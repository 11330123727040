import {request} from "../../util/Request";
import Error from "../../util/Error";

const MANUFACTURER_MAIL_FORM_STATE = {
    id: null,
    emails: [],
    subject: null,
    content: null,
    note: null,
}

export default {
    data() {
        return {
            manufacturerMailFormFields: {...MANUFACTURER_MAIL_FORM_STATE},
        }
    },
    methods: {
        async handleManufacturerEmailSubmit() {
            try {
                const response = await request({
                    url: 'inbound/loads/send/approval/request/to/manufacturer',
                    method: 'post',
                    data: {...this.manufacturerMailFormFields}
                })

                this.manufacturerMailFormFields = {...MANUFACTURER_MAIL_FORM_STATE}
                this.$bvModal.hide('manufacturer-email')
                this.loadList()
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
        handleManufacturerMailChange(event) {
            this.manufacturerMailFormFields.emails = _.filter(event, (item) => this.validateEmail(item))
        },
        handleManufacturerMailClose() {
            this.manufacturerMailFormFields = {...MANUFACTURER_MAIL_FORM_STATE}
            this.$bvModal.hide('manufacturer-email')
        },
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    }
}
