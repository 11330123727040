<template>
    <div v-if="show">
        <div class="card" v-show="operation !== 'detail'">
            <div class="card-header card-header-flex pb-2">
                <div class="w-100 mt-2">
                    <div class="row">
                        <div class="col-8">
                            <h5 class="mt-3 ml-0 mr-3 mb-2">
                                <strong>
                                    <template v-if="operation === null">
                                        {{$t('title.transportOrder')}}
                                        <span v-if="pagination.total" >({{pagination.total}})</span>
                                    </template>
                                    <template v-else>{{ $t(operationTitle) }}</template>
                                </strong>
                            </h5>
                        </div>
                        <div class="col-4 text-right">
                            <div v-if="operation === null">
                                <div class="mt-3">
                                    <!-- Using components -->
                                    <b-input-group class="mt-3">
                                        <b-form-input type="search" class="form-control form-control-sm"
                                                      :placeholder="$t('input.whatAreYouLookingFor')"
                                                      v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                      variant="outline-info"
                                                      @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('inboundloadsview')">
                                                <i class="fa fa-filter"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.exportExcel')"
                                                      variant="outline-info"
                                                      @click="handleExcelExportClick" v-b-tooltip.hover
                                                      v-if="$global.hasPermission('inboundloadsview')">
                                                <i class="fa fa-file-excel-o"></i>
                                            </b-button>
                                            <b-button size="sm" :title="$t('button.title.resetList')"
                                                      variant="outline-info"
                                                      @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                <i class="fa fa-refresh"></i>
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </div>
                            </div>
                            <div v-else>
                                <b-button variant="warning" size="sm" class="mt-3"
                                          @click="handleOperationClose()"
                                          v-b-tooltip.hover :title="$t('button.title.cancel')">
                                    <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                </b-button>
                            </div>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.w-100 -->
            </div><!-- /.card-header -->
            <div class="card-body">
                <div class="inbound-table" v-show="!operation">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             :tbody-tr-class="rowClass"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(load_number)="{ item, index, detailsShowing}">
                            <a @click="toggleDetails(item)" class="d-inline in-center">
                                <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                            </a>
                            {{ item.load_number }}
                        </template>
                        <template v-slot:cell(supplier_carrier_id)="record">
                            {{(record.item.supplier_carrier || {}).name}}
                        </template>
                        <template v-slot:cell(from_location)="{item}">
                            <span v-if="item.from_location">
                                <print-ad :item="item.from_location" titled="1"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(to_location)="{item}">
                            <span v-if="item.to_location">
                                <print-ad :item="item.to_location" titled="1"></print-ad>
                            </span>
                        </template>
                        <template v-slot:cell(transport_vehicle_id)="record">
                            <span v-if="record.item.transport_vehicle">
                                <span v-if="record.item.transport_vehicle.truck_plate_number"> {{ record.item.transport_vehicle.truck_plate_number }} </span>
                                <span v-if="record.item.transport_vehicle.trailer_plate_number"> | {{ record.item.transport_vehicle.trailer_plate_number }} </span>
                            </span>
                        </template>
                        <template v-slot:cell(driver_id)="record">
                            {{record.item.driver ? record.item.driver.name : ''}}
                        </template>
                        <template v-slot:cell(loading_date)="record">
                            <span v-if="record.item.from_estimated_loading_time">
                                {{$global.utcDateToLocalDate(record.item.from_estimated_loading_time) }}
                            </span>
                            <span v-if="record.item.to_estimated_loading_time">
                                - {{$global.utcDateToLocalDate(record.item.to_estimated_loading_time) }}
                            </span>
                        </template>
                        <template v-slot:cell(status)="record">
                            <div>
                                <ibl-status
                                    :label="record.item.status == 4 && record.item.supplier_carrier_reconfirm_at ? 'Confirmed' : false"
                                    :status="record.item.status"></ibl-status>
                            </div>
                            <ibla-status v-if="(record.item.approval_status < 3)" :status="record.item.approval_status"
                                         :comment="(record.item.load_approval || {}).comments"></ibla-status>
                            <template
                                v-if="record.item.status >=2 && record.item.status <= 3 && (record.item.does_m_l_approval_required > 0 && !record.item.manufacturer_approval_accepted_at)">
                                <br>
                                <b-badge variant="danger" :title="$t('title.earlyTransportOrder')" v-b-tooltip.hover>
                                    {{$t('title.earlyTransportOrder')}}
                                </b-badge>
                            </template>
                        </template>
                        <template v-slot:cell(updated_at)="record">
                            {{$global.utcDateToLocalDate(record.item.updated_at) }}
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('edit', record.item.id)"
                               :title="$t('button.title.editItem')"
                               v-if="$global.hasPermission('inboundloadsupdate')
                               && $global.hasAnyRole(['superadmin', 'efl'])
                                && record.item.status <= 3" class="ml-1"
                               v-b-tooltip.hover>
                                <i class="fe fe-edit"></i>
                            </a>
                            <a @click="handleManufacturerEmailSendClick(record.item.id)" class="ml-1"
                               v-if="record.item.status === 4 && record.item.does_m_l_approval_required > 0 && $global.hasAnyRole(['superadmin', 'efl'])"
                               :title="record.item.manufacturer_approval_sent_at ? $t('button.title.approvalRequestSent') : $t('button.title.sendApprovalRequest')"
                               :class="record.item.manufacturer_approval_sent_at ? 'text-info': ''"
                               v-b-tooltip.hover>
                                <i class="fe fe-mail"></i>
                            </a>
                            <a @click="handleManufacturerEmailApprovalClick(record.item.id)"
                               v-if="record.item.manufacturer_approval_sent_at && !record.item.manufacturer_approval_accepted_at && record.item.does_m_l_approval_required > 0 && $global.hasAnyRole(['superadmin', 'efl'])"
                               :title="$t('button.title.manufacturerApproval')"
                               class="ml-1" v-b-tooltip.hover>
                                <i class="fe fe-check-circle"></i>
                            </a>
                            <a @click="handleLoadApprovalClick(record.item.id)"
                               v-if="(record.item.approval_status === 1 || record.item.approval_status === 2) && record.item.can_approve && $global.hasAnyRole(['superadmin', 'efl'])"
                               :title="$t('button.title.loadApproval')"
                               class="ml-1" v-b-tooltip.hover>
                                <i class="fe fe-check-circle"></i>
                            </a>
                            <a-popconfirm title="Are you sure？" @confirm="handleDeleteClick(record.item.id)"
                                          v-if="$global.hasPermission('inboundloadsdestroy') && record.item.status <= 3">
                                <i slot="icon" class="fe fe-trash"></i>
                                <a class="ml-1" :title="$t('button.title.deleteItem')" v-b-tooltip.hover>
                                    <i class="fe fe-trash"></i>
                                </a>
                            </a-popconfirm>
                            <a @click="setOperation('detail', record.item.id)"
                               :title="$t('button.title.detailItem')" class=" ml-1"
                               v-if="$global.hasPermission('inboundloadsview') && $global.hasAnyRole(['superadmin', 'efl', 'efluser'])"
                               v-b-tooltip.hover>
                                <i class="icmn-info"></i>
                            </a>
                        </template>
                        <template v-slot:row-details="{ item }">
                            <b-card>
                                <b-list-group flush>
                                    <b-list-group-item><strong>{{$t('column.comments')}}</strong>:
                                        {{item.comments}}
                                    </b-list-group-item>
                                </b-list-group>
                            </b-card>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]"
                                           size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                v-model="pagination.current"
                                :total-rows="pagination.total"
                                :per-page="pagination.perPage"
                                :first-text="$t('paginations.first')"
                                :prev-text="$t('paginations.prev')"
                                :next-text="$t('paginations.next')"
                                :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                    <div class="mt-2">
                        <h6 class="load-status-legend">
                            <i class="icmn-info font-size-21"></i>
                            <a href="javascript:" class="ml-2" v-b-modal.load-status-legend>{{$t('title.loadStatusLegend')}}</a>
                        </h6>
                    </div>
                </div><!-- /.inbound-table-->

                <div class="inbound-operation">
                    <div v-if="operation === 'edit'">
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 5 }"/>
                                </b-col>
                                <b-col cols="12" v-show="global.pendingRequests <= 0">
                                    <b-row>
                                        <b-col md="6" lg="6" sm="12">
                                            <b-form-group
                                                :label="$t('input.supplierCarrier')+' *'"
                                                label-for="supplier_id"
                                                :invalid-feedback="formErrors.first('supplier_carrier_id') || formErrors.first('price_id') || formErrors.first('price_period_id') || formErrors.first('route_id')"
                                            >
                                                <template v-if="formFields.is_spot">
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.suppliers"
                                                        placeholder=""
                                                        v-model="formFields.supplier_carrier_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('supplier_carrier_id') || formErrors.has('price_id') || formErrors.has('price_period_id') || formErrors.has('route_id'))}]"
                                                        @select="(value, instance) => handleSpotSupplierChange(value)"
                                                    />
                                                </template>
                                                <template v-else>
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.suppliers"
                                                        placeholder=""
                                                        v-model="formFields.supplier_carrier_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('supplier_carrier_id') || formErrors.has('price_id') || formErrors.has('price_period_id') || formErrors.has('route_id'))}]"
                                                        @select="(value, instance) => handleSupplierChange(value)"
                                                    />
                                                </template>
                                            </b-form-group>
                                        </b-col>

                                        <b-col md="6" lg="6" sm="12">
                                            <b-form-group
                                                :label="$t('input.comment')"
                                                label-for="comment"
                                                :invalid-feedback="formErrors.first('comments')">
                                                <b-textarea
                                                    id="comment"
                                                    v-model="formFields.comments"
                                                    type="text"
                                                    :state="((formErrors.has('comments') ? false : null))"
                                                    @focus="$event.target.select()"
                                                ></b-textarea>
                                            </b-form-group>
                                        </b-col>

                                        <b-col md="6" lg="6" sm="12">
                                            <div class="form-group">
                                                <div class="clearfix">
                                                    <span class="pull-left">
                                                        <label class="d-block">{{$t('input.order')}}</label>
                                                    </span><!-- /.pull-left -->
                                                    <span class="pull-left ml-2 mb-1">
                                                        <button class="btn btn-warning btn-sm" v-b-tooltip.hover
                                                                :title="$t('button.title.addNewOrder')" type="button"
                                                                @click="handleAddOrderClick">{{$t('button.add')}}</button>
                                                    </span><!-- /.pull-left -->
                                                </div><!-- /.clearfix -->
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.orders"
                                                    placeholder=""
                                                    v-model="selectedOrderId"
                                                    :class="[{'invalid is-invalid': (formErrors.has('order_id'))}]"/>
                                                <div class="invalid-feedback">{{formErrors.first('order_id')}}</div>
                                            </div>
                                        </b-col>

                                        <b-col sm="3"
                                               v-if="formFields.is_cc <= 0 && formFields.is_spot <= 0 && formFields.approval_status !== 4">
                                            <label class="d-block">{{$t('input.supervisor')}} *</label>
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.supervisors"
                                                v-model="formFields.assignee_id"
                                                :disabled="formFields.approval_status > 2"
                                                placeholder=""
                                                :class="[{'invalid is-invalid': (formErrors.has('assignee_id'))}]"/>
                                            <div class="invalid-feedback">{{formErrors.first('assignee_id')}}</div>
                                        </b-col>

                                        <b-col sm="3" v-if="formFields.status == 3">
                                            <b-form-group
                                                :label="$t('input.status') + ':'"
                                                :invalid-feedback="formErrors.first('announce')"
                                            >
                                                <b-form-checkbox v-model="formFields.announce" name="announce">
                                                    {{$t('title.announce')}}
                                                </b-form-checkbox>
                                            </b-form-group>
                                        </b-col>

                                        <b-col sm="12">
                                            <div class="form-group mb-0">
                                                <b-form-group label="Loading Type" v-slot="{ ariaDescribedby }">
                                                    <b-form-radio v-model="formFields.truck_load_type" :aria-describedby="ariaDescribedby" name="some-radios" value="ftl">FTL</b-form-radio>
                                                    <b-form-radio v-model="formFields.truck_load_type" :aria-describedby="ariaDescribedby" name="some-radios" value="spl">SPL</b-form-radio>
                                                </b-form-group>
                                            </div>
                                        </b-col>

                                        <b-col sm="12">
                                            <h3 class="text-secondary">{{$t('title.existingOrders')}}</h3>
                                            <hr/>

                                            <small class="text-danger" v-if="formErrors.has('orders')">{{formErrors.first('orders')}}</small>
                                            <table class="table table-hover"
                                                   v-if="editLoad && editLoad.inbound_load_orders">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{{$t('column.vin')}}</th>
                                                    <th>{{$t('column.fromLocation')}}</th>
                                                    <th>{{$t('column.toLocation')}}</th>
                                                    <th>{{$t('column.brand')}}</th>
                                                    <th>{{$t('column.model')}}</th>
                                                    <th>{{$t('column.client')}}</th>
                                                    <th>{{$t('column.dealer')}}</th>
                                                    <th>{{$t('column.action')}}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(ilo, index) in editLoad.inbound_load_orders"
                                                    :class="{'bg-light-danger': _.includes(formFields.deleted_orders, ilo.order.id)}">
                                                    <td>{{index + 1}}</td>
                                                    <td>{{ilo.order.vin_number}}</td>
                                                    <td>
                                                        <print-ad :item="ilo.order.from_location"></print-ad>
                                                    </td>
                                                    <td>
                                                        <print-ad :item="ilo.order.to_location"></print-ad>
                                                    </td>
                                                    <td>{{(ilo.order.brand || {}).title}}</td>
                                                    <td>
                                                        <div>{{(ilo.order.model || {}).title}}</div>
                                                        <div>{{(ilo.order.model || {}).version_code}}</div>
                                                    </td>
                                                    <td>{{ilo.order.client.company_name}}</td>
                                                    <td>{{(ilo.order.dealer || {}).name}}</td>
                                                    <td>
                                                        <a-popconfirm title="Are you sure？"
                                                                      @confirm="handleDeleteOrderClick(ilo.order.id)"
                                                                      v-if="!_.includes(formFields.deleted_orders, ilo.order.id)">
                                                            <i slot="icon" class="fe fe-trash"></i>
                                                            <a class=" ml-1"
                                                               :title="$t('button.title.deleteItem')"
                                                               v-b-tooltip.hover>
                                                                <i class="fe fe-trash"></i>
                                                            </a>
                                                        </a-popconfirm>
                                                        <template v-if="formFields.new_orders.length <= 0">
                                                            <a class="ml-1"
                                                               v-if="_.includes(formFields.deleted_orders, ilo.order.id) && formFields.new_orders.length <= 0"
                                                               @click="handleUndoDeleteClick(ilo.order.id)"
                                                               :title="$t('button.title.undo')"
                                                               v-b-tooltip.hover>
                                                                <i class="fa fa-undo"></i>
                                                            </a>
                                                        </template>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>

                                            <h3 class="text-secondary">{{$t('title.newOrders')}}</h3>
                                            <hr/>
                                            <table class="table table-hover"
                                                   v-if="editLoad && formFields.new_orders.length">
                                                <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>{{$t('column.vin')}}</th>
                                                    <th>{{$t('column.fromLocation')}}</th>
                                                    <th>{{$t('column.toLocation')}}</th>
                                                    <th>{{$t('column.brand')}}</th>
                                                    <th>{{$t('column.model')}}</th>
                                                    <th>{{$t('column.client')}}</th>
                                                    <th>{{$t('column.dealer')}}</th>
                                                    <th>{{$t('column.action')}}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(ilo, index) in formFields.new_orders">
                                                    <td>{{index + 1}}</td>
                                                    <td>{{ilo.vin_number}}</td>
                                                    <td>
                                                        <print-ad :item="ilo.from_location"></print-ad>
                                                    </td>
                                                    <td>
                                                        <print-ad :item="ilo.to_location"></print-ad>
                                                    </td>
                                                    <td>{{(ilo.brand || {}).title}}</td>
                                                    <td>
                                                        <div>{{(ilo.model || {}).title}}</div>
                                                        <div>{{(ilo.model || {}).version_code}}</div>
                                                    </td>
                                                    <td>{{ilo.client.company_name}}</td>
                                                    <td>{{(ilo.dealer || {}).name}}</td>
                                                    <td>
                                                        <a-popconfirm title="Are you sure？"
                                                                      @confirm="handleDeleteNewOrderClick(ilo.id)">
                                                            <i slot="icon" class="fe fe-trash"></i>
                                                            <a class=" ml-1"
                                                               :title="$t('button.title.deleteItem')"
                                                               v-b-tooltip.hover>
                                                                <i class="fe fe-trash"></i>
                                                            </a>
                                                        </a-popconfirm>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <hr/>

                                            <b-row class="operation-footer">
                                                <b-col sm="12">
                                                    <b-button
                                                        size="sm"
                                                        type="submit"
                                                        variant="primary"
                                                        :disabled="global.pendingRequests > 0"
                                                        v-b-tooltip.hover :title="$t('button.title.save')"
                                                    >
                                                        <clip-loader style="display: inline" :loading="true"
                                                                     color="#fff"
                                                                     size="12px"
                                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                                        <i class="fa fa-save mr-1"></i>
                                                        {{$t('button.save')}}
                                                    </b-button>
                                                    <b-button variant="warning" class="ml-3"
                                                              size="sm" @click="handleOperationClose()"
                                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </form>
                    </div>
                </div><!--/.inbound-operation-->

                <div class="filter-container">
                    <a-drawer
                        placement="left"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="filters.visible = !filters.visible"
                        :visible="!operation && filters.visible"
                        :zIndex="10"
                        :title="$t('title.advanceFilters')"
                    >
                        <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.brands')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.brands"
                                            placeholder=""
                                            v-model="filters.brands"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.models')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.models"
                                            placeholder=""
                                            v-model="filters.models"
                                            @input="handleSelectModelsSelect"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.code')">
                                        <treeselect
                                            :multiple="true"
                                            :options=" _.uniqBy(_.filter(dropdowns.configCode, ({ model_id }) => _.every([_.includes(filters.models, model_id)])),'id')"
                                            placeholder=""
                                            v-model="filters.configCode"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.loadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="filters.loadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.unloadingPoint')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="filters.unloadingPoint"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.drivers')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.drivers"
                                            placeholder=""
                                            v-model="filters.drivers"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.vehicles')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.vehicles"
                                            placeholder=""
                                            v-model="filters.vehicles"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')">
                                        <treeselect
                                            :multiple="true"
                                            :options="dropdowns.status"
                                            placeholder=""
                                            v-model="filters.status"
                                        />
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.fromAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group :label="$t('input.toAddedDate')">
                                        <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                           class="mb-2"></b-form-datepicker>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                    {{$t('button.close')}}
                                </b-button>
                                <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                    {{$t('button.reset')}}
                                </b-button>
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                    {{$t('button.apply')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.filter-container -->

                <b-modal no-enforce-focus id="manufacturer-email" hide-footer size="lg">
                    <template #modal-title>
                        {{$t('title.loadConfirmEmail')}}
                    </template>
                    <form @submit.prevent="handleManufacturerEmailSubmit">
                        <div class="d-block">
                            <b-form-group
                                :label="$t('input.emails')+' *'"
                                label-for="email"
                                :invalid-feedback="formErrors.first('emails')"
                            >
                                <div :class="formErrors.first('emails') ? 'is-invalid':''">
                                    <a-select mode="tags"
                                              v-model="manufacturerMailFormFields.emails"
                                              @change="handleManufacturerMailChange">
                                        <a-select-option v-for="email in dropdowns.emails"
                                                         :key="email">
                                            {{email}}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </b-form-group>
                            <b-form-group
                                :label="$t('input.subject')+' *'"
                                label-for="subject"
                                :invalid-feedback="formErrors.first('subject')"
                            >
                                <b-form-input
                                    id="subject"
                                    v-model="manufacturerMailFormFields.subject"
                                    type="text"
                                    :state="((formErrors.has('subject') ? false : null))"
                                    ref="subject"
                                    @focus="$event.target.select()"
                                ></b-form-input>
                            </b-form-group>
                            <b-form-group
                                :label="$t('input.content')+' *'"
                                label-for="content"
                                :invalid-feedback="formErrors.first('content')"
                            >
                                <div class="mb-3 min-height-375" :class="formErrors.first('content') ? 'is-invalid':''">
                                    <ckeditor :editor="editor" v-model="manufacturerMailFormFields.content"
                                              :config="editorConfig"></ckeditor>
                                </div>
                            </b-form-group>
                            <div>
                                <small>{{$t('msc.systemWillAddExcelAttachment')}}</small>
                            </div>
                        </div>
                        <hr>
                        <div class="text-right">
                            <b-button
                                size="sm"
                                type="submit"
                                variant="primary"
                                :disabled="global.pendingRequests > 0"
                                v-b-tooltip.hover
                            >
                                <clip-loader style="display: inline" :loading="true" color="#fff"
                                             size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                                <i class="fa fa-save mr-1"></i>
                                {{$t('button.send')}}
                            </b-button>
                            <b-button variant="warning" class="ml-3"
                                      size="sm" @click="handleManufacturerMailClose()"
                                      v-b-tooltip.hover :title="$t('button.title.cancel')">
                                <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                            </b-button>
                        </div>
                    </form>
                </b-modal><!-- /.manufacturer-email -->

                <div class="manufacturer-mail-approval">
                    <a-drawer
                        placement="right"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="manufacturerMailApprovalFormFields.id = null"
                        :visible="!operation && !(!manufacturerMailApprovalFormFields.id)"
                        :zIndex="10"
                        :title="$t('title.manufacturerApproval')"
                    >
                        <form @submit.prevent="handleManufacturerEmailApprovalSubmit" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.note')"
                                        label-for="note"
                                        :invalid-feedback="formErrors.first('note')"
                                    >
                                        <b-textarea
                                            id="note"
                                            v-model="manufacturerMailApprovalFormFields.note"
                                            :state="((formErrors.has('note') ? false : null))"
                                            ref="note"
                                            @focus="$event.target.select()"
                                        ></b-textarea>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="primary" button="submit" type="filled"
                                          :disabled="global.pendingRequests > 0"
                                          :title="$t('button.title.update')" v-b-tooltip.hover>
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                                    {{$t('button.update')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.manufacturer-mail-approval -->

                <div class="inboundload-approval">
                    <a-drawer
                        placement="right"
                        :width="'360px'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="inboundLoadApprovalFormFields.id = null"
                        :visible="!operation && !(!inboundLoadApprovalFormFields.id)"
                        :zIndex="10"
                        :title="$t('title.loadApproval')"
                    >
                        <form @submit.prevent="handleLoadApprovalSubmit" autocomplete="off">
                            <b-row>
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.status')"
                                        label-for="status"
                                        :invalid-feedback="formErrors.first('status')"
                                    >
                                        <treeselect
                                            :multiple="false"
                                            :options="[{id:2,label:'Load Approval Rejected'},{id:3,label:'Load Approval Confirmed'}]"
                                            placeholder=""
                                            v-model="inboundLoadApprovalFormFields.status"
                                            :class="[{'invalid is-invalid': (formErrors.has('status'))}]"/>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        :label="$t('input.comment')"
                                        label-for="comments"
                                        :invalid-feedback="formErrors.first('comments')"
                                    >
                                        <b-textarea
                                            id="comments"
                                            v-model="inboundLoadApprovalFormFields.comments"
                                            :state="((formErrors.has('comments') ? false : null))"
                                            @focus="$event.target.select()"
                                        ></b-textarea>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </b-row>
                            <div class="drawer-footer">
                                <b-button size='sm' variant="primary"
                                          :disabled="global.pendingRequests > 0"
                                          button="submit" type="filled"
                                          :title="$t('button.title.update')" v-b-tooltip.hover>
                                    <clip-loader style="display: inline" :loading="true" color="#fff"
                                                 size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                                    {{$t('button.update')}}
                                </b-button>
                            </div><!-- /.drawer-footer -->
                        </form>
                    </a-drawer>
                </div><!-- /.inboundload-approval -->
            </div><!-- /.card-body-->
        </div><!-- /.card -->
        <div v-if="operation === 'detail'">
            <detail :handle-close-operation="handleOperationClose"></detail>
        </div>

        <!-- # Excel Export -->
        <b-modal id="excel-export-modal" v-model="exportModal.visibility" hide-footer>
            <template #modal-title>
                {{$t('title.exportExcel')}}
            </template>
            <div>
                <form @submit.prevent="handleExcelExportSubmitClick">
                    <div class="mb-5">
                        <b-form-group>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportSelectAllClick">{{$t('input.checkAll')}}</a>
                            <a href="javascript:;" class="p-3" @click="handleExcelExportUnSelectAllClick">{{$t('input.unCheckAll')}}</a>
                        </b-form-group>
                        <hr>
                        <table class="table table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>{{$t('title.columns')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(column, index) in exportModal.columns">
                                <td>{{index + 1}}</td>
                                <td>
                                    <b-form-checkbox v-model="column.show"
                                                     name="check-button">{{column.label}}
                                    </b-form-checkbox>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="drawer-footer">
                        <json-excel
                            class="btn btn-primary btn-sm"
                            :fields="exportModal.headers"
                            worksheet="transportOrders"
                            :fetch="handleExcelExportSubmitClick"
                            name="tranport_orders.xls">
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.export')}}
                        </json-excel>
                        <b-button variant="warning" class="ml-3"
                                  size="sm" @click="handleExcelExportCancelClick()"
                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                        </b-button>
                    </div>
                </form>
            </div>
        </b-modal><!--/#excel-export-modal-->

        <b-modal no-enforce-focus id="load-status-legend" hide-footer size="lg">
            <template #modal-title>
                {{$t('title.loadStatusLegend')}}
            </template>
            <div class="table-responsive">
                <table class="table table-bordered bg-white">
                    <thead>
                    <tr>
                        <th>{{$t('column.status')}}</th>
                        <th>{{$t('column.meaning')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <b-badge variant="dark">{{$t('title.announced')}}</b-badge>
                            <br/>
                            <b-badge variant="danger">
                                {{$t('title.earlyTransportOrder')}}
                            </b-badge>
                        </td>
                        <td>
                            <span class="meaning-title">{{$t('msc.etoIsSentViaPlatformToCarrier')}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b-badge variant="success">{{$t('title.accepted')}}</b-badge>
                            <br/>
                            <b-badge variant="warning">
                                {{$t('title.loadApprovalPending')}}
                            </b-badge>
                        </td>
                        <td>
                            <span class="meaning-title">{{$t('msc.carriersAnnouncementNeedsToBeValidated')}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b-badge variant="success">{{$t('title.accepted')}}</b-badge>
                        </td>
                        <td>
                            <span class="meaning-title">
                                {{$t('msc.theTransportAnnouncementIsValidatedEarlyTransportOrderBecomesTransportOrder')}}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b-badge variant="success">{{$t('title.confirmed')}}</b-badge>
                        </td>
                        <td>
                            <span class="meaning-title">
                                {{$t('msc.finalTransportOrderIsVerifiedByCarrier')}}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b-badge variant="secondary">{{$t('title.inTransit')}}</b-badge>
                        </td>
                        <td>
                            <span class="meaning-title">
                                {{$t('msc.theLoadIsPickUpAndItIsOnTheWayToItsDeliveryDestination')}}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b-badge variant="info">{{$t('title.unloadRequested')}}</b-badge>
                        </td>
                        <td>
                            <span class="meaning-title">
                                {{$t('msc.carriersInformationThatTheTruckHasUnloadedAtTheDestinationCompoundStillWaitingForCompoundToConfirmTheReceiptOfTheLoad')}}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b-badge variant="success">{{$t('title.unloadedCompound')}}</b-badge>
                        </td>
                        <td>
                            <span class="meaning-title">
                                {{$t('msc.compoundConfirmedThatTheTruckHasBeenUnloadedAtCompound')}}
                            </span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import ListingMixin from '../../util/ListingMixin'
    import Error from '../../util/Error'
    import {mapState} from 'vuex'
    import {request} from '../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from './detail'
    import moment from 'moment-timezone'
    import qs from "qs"
    import ManufacturerEmailSendMixin from "./ManufacturerEmailSendMixin";
    import ManufacturerEmailApprovalMixin from "./ManufacturerEmailApprovalMixin";
    import LoadApprovalMixin from "./LoadApprovalMixin";
    import ExcelExportMixin from "./ExcelExportMixin";
    import JsonExcel from "vue-json-excel"

    const FORM_STATE = {
        from_location_id: null,
        to_location_id: null,
        price_id: null,
        price_period_id: null,
        supplier_carrier_id: null,
        assignee_id: null,
        truck_load_type: 'ftl',
        approval_status: 0,
        is_cc: 0,
        is_spot: 0,
        id: null,
        comments: null,
        inbound_load_orders: [],
        status: 2,
        limit: 1, // loading_factors
        announce: false,
        _method: 'post',
        deleted_orders: [],
        new_orders: [],
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        configCode: [],
        drivers: [],
        vehicles: [],
        status: [1,2,3,4,5,6,7],
        loadingPoint: [],
        unloadingPoint: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.loadNumber'),
            key: 'load_number',
            sortable: true,
            class: 'w-130'
        },
        {
            label: self.$t('column.supplierCarrier'),
            key: 'supplier_carrier_id',
            sortable: true,
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'from_location',
            sortable: false,
        },
        {
            label: self.$t('column.unLoadingPoint'),
            key: 'to_location',
            sortable: false,
        },
        {
            label: self.$t('column.driver'),
            key: 'driver_id',
            sortable: true,
        },
        {
            label: self.$t('column.transportVehicle'),
            key: 'transport_vehicle_id',
            sortable: true,
        },
        {
            label: self.$t('column.loadingFactors'),
            key: 'loading_factors',
            sortable: true,
        },
        {
            label: self.$t('column.qty'),
            key: 'qty',
            sortable: true,
        },
        {
            label: self.$t('column.loadingDate'),
            key: 'loading_date',
            sortable: false,
        },
        {
            label: self.$t('column.status'),
            key: 'status',
            sortable: true,
        },
        {
            label: self.$t('column.modifiedAt'),
            key: 'updated_at',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['inboundloadsupdate', 'inboundloadsview'])
            ? {
                label: self.$t('column.action'),
                class: 'text-right w-100px',
                key: 'action',
            } : {}),
    ];

    export default {
        mixins: [ListingMixin, ManufacturerEmailSendMixin, ManufacturerEmailApprovalMixin, LoadApprovalMixin, ExcelExportMixin],
        components: {
            Treeselect,
            Detail,
            JsonExcel,
        },
        data() {
            return {
                operationTitle: 'title.orders',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'inbound/loads',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    suppliers: [],
                    orders: [],
                    countries: [],
                    supervisors: [],
                    brands: [],
                    models: [],
                    configCode: [],
                    drivers: [],
                    vehicles: [],
                    currencies: [],
                    status: [
                        {id: 1, label: this.$t('title.created')},
                        {id: 2, label: this.$t('title.announced')},
                        {id: 3, label: this.$t('title.rejected')},
                        {id: 4, label: this.$t('title.confirmed')},
                        {id: 5, label: this.$t('title.loaded')},
                        {id: 6, label: this.$t('title.inTransit')},
                        {id: 7, label: this.$t('title.unloaded')},
                        {id: 8, label: this.$t('title.unloadedCompound')},
                    ],
                    emails: [],
                    locations:[],
                },
                show: true,
                editLoad: null,
                selectedOrderId: null,
            }
        },
        mounted() {
            this.getBrands();
            this.getModels();
            this.getDrivers();
            this.getTransportVehicles();
            this.getLocations();
            this.getCountries();
            this.getSupervisors();
            this.getCurrencies();
            if (this.$route.query && this.$route.query.operation === 'edit' && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }

            this.$title = this.$t('topBar.navigations.modules.inbound')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                if ((this.editLoad.inbound_load_orders.length + this.formFields.new_orders.length) === this.formFields.deleted_orders.length) {
                    const errors = {orders: [this.$t('validation.minimumOrderRequired')]}
                    this.formErrors = new Error(errors)
                    return false;
                }

                try {
                    const params = {
                        id: this.formFields.id,
                        assignee_required: (
                            (this.formFields.is_cc > 0 || this.formFields.is_spot > 0) ? 0 : (
                                (this.formFields.approval_status == 4 || this.formFields.approval_status == 3) ? 0 : 1)
                        ),
                        price_id: this.formFields.price_id,
                        price_period_id: this.formFields.price_period_id,
                        assignee_id: this.formFields.assignee_id,
                        supplier_carrier_id: this.formFields.supplier_carrier_id,
                        supplier_compound_id: this.formFields.supplier_compound_id,
                        approval_status: this.formFields.approval_status,
                        comments: this.formFields.comments,
                        _method: 'post',
                        deleted_orders: this.formFields.deleted_orders,
                        new_orders: this.formFields.new_orders.map((item) => ({id: item.id})),
                        status: this.formFields.status,
                        announce: this.formFields.announce,
                        truck_load_type: this.formFields.truck_load_type,
                    }

                    if (this.editLoad.is_spot > 0) {
                        params.is_spot = 1;
                    }

                    const response = await request({
                        url: 'inbound/loads/update',
                        method: 'post',
                        data: params
                    })

                    if (this.formFields.id) {
                        this.itemUpdated()
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleEditClick(id) {
                this.dropdowns.orders = []
                this.formFields.new_orders = []
                this.formFields.deleted_orders = []
                this.operationTitle = this.$t('title.editLoad')

                request({url: `/inbound/loads/detail/${id}`})
                    .then((response) => {
                        const {data} = response
                        this.editLoad = data
                        this.operationTitle = this.$t('title.editLoad') + `${data.is_cc > 0 ? ' CC - ' : ''}${data.is_spot > 0 ? ' SPOT - ' : ''}#${data.load_number}`;
                        this.formFields.id = data.id
                        this.formFields.is_cc = data.is_cc
                        this.formFields.approval_status = data.approval_status
                        this.formFields.assignee_id = (data.approver ? data.approver.id : null)
                        this.formFields.supplier_carrier_id = (data.supplier_carrier ? data.supplier_carrier.id : null)
                        this.formFields.supplier_compound_id = (data.supplier_compound ? data.supplier_compound.id : null)
                        this.formFields.limit = data.loading_factors
                        this.formFields.status = data.status
                        this.formFields.truck_load_type = data.truck_load_type
                        this.formFields.from_location_id = data.inbound_load_orders[0].order.from_location.id
                        this.formFields.to_location_id = data.inbound_load_orders[0].order.to_location.id

                        if (data.is_spot > 0) {
                            this.formFields.is_spot = 1
                        }

                        this.getOrderNearLocationSupplier(data.inbound_load_orders.map(item => item.order.id), data.created_at)
                        this.getOrders(data)
                    })
                    .catch((error) => {
                        this.handleServerError(error)
                    })
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/inbound/loads/delete',
                        data: {
                            id: id,
                        },
                    })

                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.itemDeleteFailsBecsDependency()
                        return false;
                    }

                    this.itemDeleteFails()
                }
            },
            async getOrderNearLocationSupplier(ids, created_at = null) {
                try {
                    const response = await request({
                        url: '/dropdowns/orders/matched/location/carrier/suppliers',
                        method: "post",
                        data: {
                            orders: ids,
                            created_at: (created_at ? moment(created_at).format('YYYY-MM-DD') : null)
                        }
                    })

                    const {data} = response
                    this.dropdowns.suppliers = data

                    if(created_at && this.editLoad.is_spot > 0) {
                        this.handleSpotSupplierChange({id: this.formFields.supplier_carrier_id})
                    } else if(created_at) {
                        this.handleSupplierChange({id: this.formFields.supplier_carrier_id})
                    }
                } catch (e) {
                    this.dropdowns.suppliers = []
                }
            },
            async getSuppliers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post",
                    })

                    const {data} = response
                    this.dropdowns.suppliers = data
                } catch (e) {
                    this.dropdowns.suppliers = []
                }
            },
            async getOrders(item) {
                try {
                    const params = {
                        id: item.id,
                        from_location: item.from_location.id,
                        to_location: item.to_location.id,
                        brands: (_.map(item.inbound_load_orders, (item) => item.order.brand.id)),
                        // model: item.inbound_load_orders[0].order.model.id,
                        status: ((item.is_cc > 0) ? [8] : [1]),
                        is_cc: item.is_cc,
                        is_spot: item.is_spot,
                    }

                    const {data} = await request({
                        method: "post",
                        url: 'dropdowns/orders/inbound/update',
                        params: params,
                        paramsSerializer: ((params) => qs.stringify(params)),
                    })

                    this.dropdowns.orders = data.map(item => ({
                        id: item.id,
                        label: `${item.order_id} | ${item.vin_number}`,
                        item: item
                    }))
                } catch (e) {

                }
            },
            async handleManufacturerEmailSendClick(id) {
                try {
                    const response = await request({
                        url: 'inbound/loads/order/detail/' + id,
                    })

                    const {data} = response
                    this.manufacturerMailFormFields.id = id;
                    this.dropdowns.emails = _.uniq(data);
                    this.$bvModal.show('manufacturer-email')
                } catch (e) {
                    this.handleServerError(e)
                }
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            async getSupervisors() {
                try {
                    const response = await request({
                        url: '/dropdowns/supervisors',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supervisors = data

                } catch (e) {
                    this.dropdowns.supervisors = []
                }
            },
            async getCurrencies() {
                try {
                    const response = await request({
                        url: '/dropdowns/currencies',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.currencies = data

                } catch (e) {
                    this.dropdowns.currencies = []
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({id: item.id, label: `${item.label}`}))
                    this.dropdowns.configCode = data.map(item => ({
                        id: item.version_code,
                        label: item.version_code,
                        model_id: item.id
                    }))
                } catch (e) {
                    this.dropdowns.models = []
                    this.dropdowns.configCode = []
                }
            },
            async getDrivers() {
                try {
                    const response = await request({
                        url: '/dropdowns/drivers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.drivers = data
                } catch (e) {
                    this.dropdowns.drivers = []
                }
            },
            async getTransportVehicles() {
                try {
                    const response = await request({
                        url: '/dropdowns/transport/vehicles',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.vehicles = data
                } catch (e) {
                    this.dropdowns.vehicles = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = _.map(data,(i) => {
                        return{
                            id:i.id,
                            label:i.city,
                        }
                    })
                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            handleSelectModelsSelect(changeValue) {
                this.filters.configCode = []
            },
            validateOrderError() {
                const errors = {}
                let hasError = false
                if (!this.selectedOrderId) {
                    errors.order_id = [this.$t('validation.required')]
                    hasError = true
                }

                const existingOrders = this.editLoad.inbound_load_orders.length - this.formFields.deleted_orders.length
                if (this.editLoad.is_spot <= 0 && (existingOrders + this.formFields.new_orders.length + 1) > this.formFields.limit && this.formFields.is_cc <= 0) {
                    errors.order_id = [this.$t('validation.orderLimitExceed')]
                    hasError = true
                }

                if (_.find(this.editLoad.inbound_load_orders, {id: this.selectedOrderId})) {
                    errors.order_id = [this.$t('validation.duplicate')]
                    hasError = true
                }

                this.formErrors = new Error(errors)
                return hasError
            },
            handleAddOrderClick() {
                if (this.validateOrderError())
                    return false

                this.formFields.new_orders.push(_.find(this.dropdowns.orders, {id: this.selectedOrderId}).item)
                this.selectedOrderId = null
            },
            handleDeleteNewOrderClick(orderId) {
                const orders = this.formFields.new_orders
                orders.splice(orderId, 1)
                this.formFields.new_orders = orders
            },
            handleManufacturerEmailApprovalClick(id) {
                this.manufacturerMailApprovalFormFields.id = id;
                this.manufacturerMailApprovalFormFields.note = null;
            },
            handleLoadApprovalClick(id) {
                this.inboundLoadApprovalFormFields.id = id;
                this.inboundLoadApprovalFormFields.status = undefined;
                this.inboundLoadApprovalFormFields.comments = null;
            },
            handleDeleteOrderClick(orderId) {
                this.formFields.deleted_orders.push(orderId)
            },
            handleUndoDeleteClick(orderId) {
                const orders = this.formFields.deleted_orders
                orders.splice(orderId, 1)
                this.formFields.deleted_orders = _.compact(orders)
            },
            hasListAccess() {
                return this.$global.hasPermission('inboundloadsview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
                this.editLoad = null
                this.selectedOrderId = null
                this.dropdowns.suppliers = []
                this.dropdowns.orders = []
                this.formFields.deleted_orders = []
                this.formFields.new_orders = []
            },
            rowClass(item, type) {
                if (!item || type !== 'row') return
                if (item.is_cc > 0) return 'bl-2 border-dark'
                if (item.is_spot > 0) return 'bl-2 border-warning'
            },
            handleSpotSupplierChange(value) {
                this.formFields.price_id = null
                this.formFields.price_period_id = null
                const groupModels = _.uniq(_.map(this.editLoad.inbound_load_orders, (item) => item.order.model.id))
                const supplier = _.find(this.dropdowns.suppliers, {id: value.id})

                if(!supplier) {
                    this.formErrors = new Error({supplier_carrier_id: [this.$t('validation.invalidPrice')]});
                    return;
                }

                const price = _.find(supplier.prices, ({route, models}) => {
                    let doesSelectedOrderContainsPriceModel = 0

                    _.map(models, (m) => {
                        if (groupModels.includes(m.id)) {
                            doesSelectedOrderContainsPriceModel++;
                        }
                    })

                    return (
                        route.from_location.id === this.formFields.from_location_id &&
                        route.to_location.id === this.formFields.to_location_id &&
                        groupModels.length === doesSelectedOrderContainsPriceModel
                    )
                });

                if (price) {
                    const price_period = _.filter(price.price_periods, (item) => (item.price_spl * 1) > 0);

                    if (price_period.length > 0 && price_period[0].price_spl > 0 && price_period[0].currency) {
                        this.formFields.price_id = price.id;
                        this.formFields.price_period_id = price_period[0].id;
                    } else {
                        this.formErrors = new Error({supplier_carrier_id: [this.$t('validation.invalidPricePeriod')]});
                    }
                } else {
                    this.formFields.price_id = null;
                    this.formFields.price_period_id = null;
                    this.formErrors = new Error({supplier_carrier_id: [this.$t('validation.invalidPrice')]});
                    this.priceNotFound();
                }
            },
            handleSupplierChange(value) {
                this.formFields.price_id = null
                this.formFields.price_period_id = null
                const groupModels = _.uniq(_.map(this.editLoad.inbound_load_orders, (item) => item.order.model.id))
                const {prices} = _.find(this.dropdowns.suppliers, {id: value.id})
                const price = _.find(prices, ({route, models}) => {
                    let doesSelectedOrderContainsPriceModel = 0

                    _.map(models, (m) => {
                        if (groupModels.includes(m.id)) {
                            doesSelectedOrderContainsPriceModel++;
                        }
                    })

                    return (
                        route.from_location.id === this.formFields.from_location_id &&
                        route.to_location.id === this.formFields.to_location_id &&
                        groupModels.length === doesSelectedOrderContainsPriceModel
                    )
                });

                if (price) {
                    this.formFields.price_id = price.id
                    this.formFields.price_period_id = price.price_periods[0].id
                } else {
                    this.priceNotFound()
                }
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80 {
        width: 80px;
    }

    .w-220 {
        width: 220px;
    }

    .in-center {
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    .order-select-chbx {
        position: absolute;
        right: -7px;
        top: -7px;
    }

    .add-load-btn {
        position: absolute;
        top: 12px;
        right: 50px;
    }

    @media screen and (max-width: 768px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }

    .min-height-375 {
        min-height: 375px;
    }

    .w-100px {
        width: 100px;
    }

    .ck-editor__editable {
        min-height: 375px;
    }

    :root {
        --ck-z-default: 100;
        --ck-z-modal: calc(var(--ck-z-default) + 999);
    }

    .load-status-legend a, .load-status-legend i {
        color: #0000ff !important;
    }

    .load-status-legend a {
        text-decoration: underline #0000ff;
    }

    .meaning-title {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
    }

    .load-status-legend a, .load-status-legend i {
        color: #0000ff !important;
    }

    .load-status-legend a {
        text-decoration: underline #0000ff;
    }

    .meaning-title {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
    }

    .load-status-legend a, .load-status-legend i {
        color: #0000ff !important;
    }

    .load-status-legend a {
        text-decoration: underline #0000ff;
    }

    .meaning-title {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
    }

    #load-status-legend table th {
        background: #4F81BD;
        color: #ffffff;
        font-weight: bolder;
        font-size: 18px;
    }
</style>
